<template>
	<el-dialog :title="!dataForm.recNo ? '新增变更记录' : '修改变更记录'" :close-on-click-modal="false" :visible.sync="visible"
		width="1400px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="8">
					<el-form-item label="变更项目" prop="contractName">
						<el-input class="selItemInput" v-model="dataForm.changeItem" placeholder="请输入" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="变更内容" prop="contractNo">
						<el-input class="selItemInput" v-model="dataForm.changeContent" placeholder="请输入" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="变更时间" prop="contractTime">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.changeTime"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				
				<el-col :span="8">
					<el-form-item label="变更   设备" prop="contractAmount">
						<el-input class="selItemInput" v-model="dataForm.changeWorkB" placeholder="请输入" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="人" prop="contractCompany">
						<el-input class="selItemInput" v-model="dataForm.changeWorkA" placeholder="请输入" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				
				<el-col :span="8">
					<el-form-item label="工作量" prop="contractAmount">
						<el-input class="selItemInput" v-model="dataForm.changeWorkC" placeholder="请输入" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="扫描件">
						<div class="addFileBox" v-for="(item,index) in fileList">
						    <div>
								<span style="display: inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: top;">
									{{item.fileName}}
								</span>
								<i class="el-icon-delete" @click="delFile(index)"></i>
							</div>
						</div>
						<el-upload
						  class="addFileBox"
						  ref="uploadFile"
						  :action="action"
						  v-if="!fileList || fileList.length < 1"
						  :http-request="uploadFile">
						  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add" style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="变更原因" prop="contractContent">
						<el-input class="selItemInput" style="width: 493px !important;" type="textarea" rows="3" v-model="dataForm.changeRsn" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="备注" prop="contractContent">
						<el-input class="selItemInput" style="width: 493px !important;" type="textarea" rows="3" v-model="dataForm.remark" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "log-add-or-update",
		data() {
			return {
				visible: false,
				inline: false,
				action: "",
				fileList:[],
				dataForm: {
					contractId:"",
					recNo:"",
					changeContent:"",
					changeItem:"",
					changeRsn:"",
					changeTime:"",
					changeWorkA:"",
					changeWorkB:"",
					changeWorkC:"",
					changeFilePath:"",
					remark:""
				},
				dataRule: {
					
				},
			}
		},
		methods: {
			init(pid, id) {
				this.fileList=[];
				this.dataForm = {
					contractId:"",
					recNo:"",
					changeContent:"",
					changeItem:"",
					changeRsn:"",
					changeTime:"",
					changeWorkA:"",
					changeWorkB:"",
					changeWorkC:"",
					changeFilePath:"",
					remark:""
				};
				this.dataForm.contractId = pid?pid:0;
				this.dataForm.recNo = id?id:0;
				if(id){
					this.getDetail();
				}
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			getDetail(){
				this.$http({
				  url: this.$store.state.httpUrl + "/business/ownercontractchange/info/"+this.dataForm.recNo,
				  method: "get",
				  params: {},
				}).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					this.dataForm = data.body
				  }
				});
			},
			delFile(index){
				this.dataForm.changeFilePath = "";
				this.fileList = [];
			},
			uploadFile(param){
				const formData = new FormData();
				formData.append('file', param.file);
				this.$refs.uploadFile.clearFiles();
				this.$http.post(
					this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/97/0", 
					formData, 
					{
					  headers: { 'Content-Type': 'multipart/form-data' }
					},
				).then(res => {
				  res = res.data
				  if (res.resultCode == 200){
					this.dataForm.changeFilePath = res.body.fileSavePath;
					this.fileList = [{
						fileName: res.body.fileName,
						filePath: res.body.fileSavePath,
						fileUri: res.body.fileUri
					}];
				  } else {
					this.$message.info('文件上传失败')
				  }
				})
			},
			dataFormSubmit() {
			  this.$refs["dataForm"].validate(valid => {
				if (valid) {
				  let _url = "/business/ownercontractchange/save";
				  if(this.dataForm.recNo && this.dataForm.recNo != 0){
					_url = "/business/ownercontractchange/update";
				  }
				  this.$http({
					url: this.$store.state.httpUrl + _url,
					method: "post",
					data: this.$http.adornData(this.dataForm)
				  }).then(({ data }) => {
					if (data && data.resultCode === 200) {
					  this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
						  this.visible = false;
						  this.$emit("refreshDataList");
						}
					  });
					} else {
					  this.$message.error(data.msg);
					}
				  });
				}
			  });
			}
		}
	}
</script>
<style lang="scss"  scoped>
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
</style>
