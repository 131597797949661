<template>
	<div>
		<el-dialog title="变更记录" :close-on-click-modal="false" :visible.sync="visible"
			width="1400px">
			<el-row v-if="showAdd">
			  <el-col :span="12">
				  <el-button v-preventReClick class="btn" type="primary" size="small" @click="addOrUpdateHandle()">添加</el-button>
			  </el-col>
			</el-row>
			<el-table
			  class="dataListTable"
			  :data="dataList"
			  header-align="center"
			  style="width: 100%; margin-top: 20px;"
			  header-cell-class-name="stepPointTHeader">
			  <el-table-column type="index" width="60" align="center">
			  </el-table-column>
			  <el-table-column prop="changeItem" label="变更项目" width="200" align="center">
			  </el-table-column>
			  <el-table-column prop="changeContent" label="变更内容" align="left" header-align="center">
			  </el-table-column>
			  <el-table-column prop="changeRsn" label="变更原因 " width="275" align="left" header-align="center">
			  </el-table-column>
			  <el-table-column prop="changeTime" label="变更时间 " width="100" align="center">
			  </el-table-column>
			  <el-table-column label="变更" align="center">
				  <el-table-column prop="changeWorkB" label="设备" width="80" align="center">
				  </el-table-column>
				  <el-table-column prop="changeWorkA" label="人" width="80" align="center">
				  </el-table-column>
				  <el-table-column prop="changeWorkC" label="工作量 " width="80" align="center">
				  </el-table-column>
			  </el-table-column>
			  <el-table-column
					  label="操作"
					  v-if="showEdit || showDelete"
					  align="center">
				  <template slot-scope="scope">
					  <el-button v-preventReClick type="danger" size="small" @click="addOrUpdateHandle(scope.row.recNo)" v-if="showEdit">修改</el-button>
					  <el-button v-preventReClick type="success" size="small" @click="deleteHandle(scope.row.recNo,scope.row.changeItem)" v-if="showDelete">删除</el-button>
<!--					  <el-button v-preventReClick type="primary" size="small"  @click="selectFile(scope.row.changeFileUri)" v-if="scope.row.showFileFlag == 1">查看文件</el-button>-->
<!--					  <div class="listSelFileBtn" @click="selectFile(scope.row.contractFileUri)" v-if="scope.row.contractPathFlg == 1">-->
<!--						  <i class="el-icon-paperclip"></i>-->
<!--						  查看文件-->
<!--					  </div>-->
				  </template>
			  </el-table-column>
			</el-table>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">返回</el-button>
			</el-row>
		</el-dialog>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getChangedLogList()"></add-or-update>
	</div>
</template>

<script>
	import AddOrUpdate from './changed-add-or-update'
	import $common from "@/utils/common.js"
	export default {
		name: "ownerContract-add-or-update",
		data() {
			return {
				showSearch: false,
				showAdd: false,
				showEdit: false,
				showDelete: false,
				visible: false,
				inline: false,
				contractId:"",
				dataList:[],
				addOrUpdateVisible: false
			}
		},
		components: {
		    AddOrUpdate
		},
		methods: {
			init(id) {
				this.contractId = id?id:0;
				this.showSearch = $common.isAuth('ownerContract:change:search');
				this.showAdd = $common.isAuth('ownerContract:change:add');
				this.showEdit = $common.isAuth('ownerContract:change:edit');
				this.showDelete = $common.isAuth('ownerContract:change:delete');
				if(id && this.showSearch){
					this.getChangedLogList();
				}
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			getChangedLogList(){
				this.$http({
				  url:  this.$store.state.httpUrl + "/business/ownercontractchange/list",
				  method: "get",
				  params: {
					contractId: this.contractId
				  },
				  }).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
					}
				});
			},
			selectFile(url){
				window.open(url);
			},
			// 新增检验项目
			addOrUpdateHandle (id){
			    this.addOrUpdateVisible = true
			    this.$nextTick(() => {
			        this.$refs.addOrUpdate.init(this.contractId,id)
			    })
			},
			deleteHandle (id,changeItem) {
				this.$confirm(`确定对[变更项目=${changeItem}]进行删除操作?`, '提示', {
			        confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
			    }).then(() => {
			        this.$http({
						url:  this.$store.state.httpUrl + "/business/ownercontractchange/delete",
			            method: 'post',
			            data: [id],
			        }).then(({data}) => {
			            if (data && data.resultCode === 200) {
			                this.$message({
			                    message: '操作成功',
			                    type: 'success',
			                    duration: 1500,
			                    onClose: () => {
			                        this.getChangedLogList()
			                    }
			                })
			            } else {
			                this.$message.error(data.msg)
			            }
			        })
			    })
			},
		}
	}
</script>
<style lang="scss"  scoped>
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
</style>
